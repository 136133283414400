/* General Styling */
.create-share-earn-hero {
  max-width: 800px;
  margin: 0 auto;
}

.main-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 38.13px;
  color: #000;
  padding: 2rem 1rem 1rem 1.7rem;
}

.title-underline {
  width: 172px;
  height: 5px;
  border-radius: 5px;
  margin-left: 1.7rem;
  background-color: #FFD700;
}

.create-share-earn-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
}

.create-share-earn-section {
  margin-bottom: 3rem;
  color: #000;
}

.earn-desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 20.07px;
}

.create-share-earn-card {
  /* background: #f8f9fa;
  border-radius: 12px; */
  /* padding: 0.5rem; */
  /* margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.create-share-earn-card.dot-details {
  background: #CFCFCF2B;
  border-left: 2px solid #FED800;
  padding-left: 1rem;
  margin-bottom: 2rem;
}


.create-share-earn-dots h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
}

.create-share-earn-card ul li {
  color: #000;
  font-size: 15px;
  font-weight: 400;
  line-height: 24.07px;
}

.content-creation h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.07px;
}

.create-share-earn-highlight {
  background: #FCFAED;
  border-left: 7px solid #FED800;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
}

.create-share-earn-highlight p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.28px;
}

.create-share-earn-warning {
  background: #FFEEEE;
  border-left: 7px solid #D40303;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  color: #000;
}

.what-to-avoid h3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 21.28px;
}

.what-to-avoid ul li>span {
  font-size: 14px;
  font-weight: 700;
  line-height: 21.28px;
}

.create-share-earn-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin: 1rem 0;
}

.create-share-earn-feature-card {
  background: white;
  padding: .6rem;
  border-radius: 10px;
  border: 1px solid #FED800;
  box-shadow: 0px 1px 5.5px 0px #0000001A;
  min-height: 120px;
}

.feature-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: #000;
}

.create-share-earn-tool-preview {
  background: #FED8001A;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
}

.create-share-earn-tool-preview img {
  max-width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 1rem 0;
  width: 50%;
}

.create-share-earn-cta-button {
  background: #6366F1;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  margin: 1rem 0;
  font-weight: bold;
}

.create-share-earn-container h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 1rem;
}


.create-share-earn-container h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-title {
    text-align: left;
  }

  .create-share-earn-tool-preview img {
    width: 100%;
  }
}