.how-it-works-section {
    width: 100%;
    /* Ensure the section spans the full width */
    /* background-color: #FFF8CF; */
    /* Background color */
    /* padding: 40px 20px; */
    /* text-align: center; */
    margin: 0 auto;
    /* Center the section */
    box-sizing: border-box;
    /* Ensure padding doesn’t exceed the width */
}

.download-page .blurred-images-container-hero-section {
    gap: 0px;
    padding: 0px;
}

.download-page .nav-item .try-btn {
    border: 1px solid;
}

.overlay-content-hero-section .hero-title {
    font-family: Open Sans;
}

.download-page .overlay-content-hero-section .cta-button {
    width: 230px;
    height: 54px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.download-page .header_nav_left,
.download-page .header_nav_right {
    display: none;
}

.download-page .navbar {
    background: #fff !important;
    border-bottom: 1px solid #000000
}

.how-it-works-section .section-title {
    font-family: Open Sans;
    font-size: 54px;
    font-weight: 600;
    line-height: 64.8px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 16px;
}

.how-it-works-section p {
    font-size: 24px;
    font-weight: 400;
    line-height: 32.68px;
    text-align: left;
    margin-bottom: 36px;
}

.steps-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* Center elements vertically */
    gap: 28px;
    flex-wrap: wrap;
    /* max-width: 1200px; */
    /* Optional: Add a max-width for content alignment */
    /* margin: 0 auto; */
    /* Center the inner content */
}

.steps-container .margin-20 {
    /* margin-top: -20px; */
}

.step {
    text-align: center;
    /* max-width: 210px; */
    width: 30%;
    height: 164px;
    border-radius: 16px;
    border: 1px solid;
    background: #FFF5BD;
    padding: 10px;
}

.step-circle {

    width: 35px;
    height: 35px;

    /* background-color: #323232;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin: 0 auto 15px; */
}

.step h3 {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 32.24px;
    letter-spacing: -0.1454544961452484px;
    text-align: left;
    color: #000;
    margin-bottom: 12px;
}

.step p {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    letter-spacing: -0.1454544961452484px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000;
}

.step-arrow-container {
    display: flex;
    align-items: center;
    /* Center the arrow vertically */
    justify-content: center;
}

.step-arrow {
    width: 100px;
    /* Adjust size for better clarity */
    height: auto;
    /* Maintain aspect ratio */
    display: block;
}

.download-page .hero-section-container {
    margin-top: 0%;
    background: #FED80042;
    height: auto;
    gap: 0px;
    border: 1px solid;
    /* min-height: 660px; */
    padding: 60px;
}


.download-page .overlay-content-hero-section {
    padding: 0px;
    flex: .6 1;
}

.download-page .how-it-works-section {
    padding-top: 0px;
}

.download-page .cta-button {
    border: 1px solid;
}

.download-page .hero-title-1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 70.4px;
}

.beyond-chat-section {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin-top: 100px;
}

.beyond-chat-section .content-container {
    display: flex;
    /* max-width: 1200px; */
    width: 100%;
    gap: 100px;
    align-items: center;
    flex-wrap: wrap;
}


.beyond-chat-section .image-container {
    flex: .6 1;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: relative;
}

.beyond-chat-section.section-3 .image-container {
    flex: 1;
  
}



.beyond-chat-section .beyond-chat-image {
    width: 100%;
    /* max-width: 400px; */
    height: auto;
    object-fit: contain;
}

.beyond-chat-section .text-container {
    flex: 1;
    text-align: left;
}

.beyond-chat-section .text-container .beyond-title {
    font-family: Open Sans;
    font-size: 54px;
    font-weight: 600;
    line-height: 62.1px;
    letter-spacing: -1.1395477056503296px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 16px;
}

.beyond-chat-section.section-3 .text-container .beyond-title,
.beyond-chat-section.section-3 .image-container .beyond-title {
    font-size: 32px;
    font-weight: 600;
    line-height: 43.58px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
    background: #323232;
    color: #fff;
}

.beyond-chat-section.section-3 .content-container {
    align-items: flex-start;
}


.beyond-chat-section.section-3 .image-container {
    display: block;
}

.beyond-chat-section.section-3 .content-container {
    gap: 0;
    max-width: 100%;
}


.beyond-chat-section.section-3 {
    /* padding: 20px 0px; */
}


/* .beyond-chat-section.section-4 .text-container .beyond-title,
.beyond-chat-section.section-4 .image-container .beyond-title {
    font-family: Open Sans;
    font-size: 34px;
    font-weight: 600;
    line-height: 46.3px;
    letter-spacing: 0.01em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;  
   
} */

.beyond-chat-section.section-4 .content-container {
    align-items: flex-start;
}


.beyond-chat-section.section-4 .image-container {
    display: block;
    padding: 25px 0px 0px 25px;
}

.beyond-chat-section.section-4 .content-container {
    gap: 0;
    border: 1px solid #333333;
}

.beyond-chat-section.section-4 .top-level-text {
    font-size: 54px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;
    border-top: 1px solid #333333;
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;
    padding: 46px;
}

.table-top-title {
    font-size: 54px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;


}



.beyond-chat-section.section-3 .beyond-title-1 {
    padding: 42px 20px 42px 20px;
}

.beyond-chat-section.section-3 .beyond-title-2 {
    padding: 42px 0px 42px 0px;
}

.beyond-chat-section.section-4 .beyond-title-1 {
    font-size: 48px;
    font-weight: 600;
    line-height: 61px;
    letter-spacing: 0.01em;
    text-align: left;
    padding-bottom: 24px;
}


.beyond-chat-section.section-4 .beyond-sub-title-1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 32.68px;
    text-align: left;
    width: 50%;
}




.beyond-chat-section .text-container p {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 32.68px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* width: 80%; */
}

.beyond-chat-section .text-container ul {
    margin-bottom: 20px;
    width: 80%;
    /* padding-left: 20px; */
}

.beyond-chat-section .text-container ul li {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    list-style: none;
    margin-bottom: 12px;
}

.beyond-chat-section .text-container ul li strong {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: contents;
}

.custom-list {
    list-style-type: none;
    /* Remove default bullets */
    padding: 0;
    margin: 0;
}

.custom-list li {
    display: flex;
    align-items: center;
    /* Aligns image and text vertically */
    margin-bottom: 15px;
    /* Spacing between list items */
}

.custom-list img {
    margin-right: 10px;
    /* Space between image and text */
}

.custom-list strong {
    margin-right: 5px;
    /* Optional spacing between bold and regular text */
}

.custom-list li:last-child {
    margin-bottom: 0;
    /* Remove bottom margin for the last item */
}


.beyond-chat-section .cta-button {
    background-color: #FED800;
    color: #222022;
    border: none;
    padding: 10px 12px 10px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s ease;
}

.beyond-chat-section .cta-button:hover {
    color: #fff;
}

.beyond-chat-section .footer_bg_left {
    left: 12%;
    width: 100%;
    z-index: -1;
    bottom: -30%;
}

.beyond-chat-section .footer_bg_right {
    right: 15%;
    width: 100%;
    z-index: -1;
    bottom: -20%;
}

/* explore-dots-section  */

.explore-dots-section {
    background-color: #fff;
    padding: 130px 20px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    display: grid;
}

.explore-dots-section .section-title {
    font-family: Open Sans;
    font-size: 54px;
    font-weight: 600;
    line-height: 70.4px;
    letter-spacing: -1.1395477056503296px;
    text-align: center;
    margin-bottom: 10px;
    color: #222022;
    width: 80%;
    margin: auto;
    z-index: 99;
    margin-bottom: 24px;
}

.explore-dots-section .section-subtitle {
    font-family: Open Sans;
    font-size: 24px;
    font-weight: 400;
    line-height: 26.15px;
    /* letter-spacing: -1.1395477056503296px; */
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    /* color: #222022; */
    margin-bottom: 20px;
}

.explore-dots-section .category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: auto;
    padding-bottom: 40px;
    width: 70%;
}

.explore-dots-section .category-buttons button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #222022;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.43px;
    /* letter-spacing: -1.1395477056503296px; */
    text-align: center;
}

.explore-dots-section .category-buttons button:hover {
    background-color: #FED800;
    color: #222022;
    border-color: #FED800;
}

.explore-dots-section .category-buttons button.active {
    background-color: #FED800;
}

/* Carousel container and View All button */
.explore-dots-section .carousel-container {
    /* display: flex;
    align-items: flex-start; 
    justify-content: flex-start;  */
    display: block;

    /* gap: 20px; */
    margin-top: 20px;
}

/* DotsCarousel styling */
.explore-dots-section .dots-carousel {
    flex: 1;
    /* Take available space */
}

.explore-dots-section .dots-carousel .dot-item {
    /* padding: 10px; */
    text-align: left;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    overflow: hidden;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    width: 90% !important;
    position: relative;
}

.explore-dots-section .dot-item img {
    /* width: 100%;
    height: auto;
    display: block;
    border-bottom: 1px solid #ddd; */
}

.explore-dots-section .dot-item .dot-image {
    width: 100%;
    height: 190px;
    display: block;
    object-fit: cover;
}

.explore-dots-section .dot-item p {
    font-size: 14px;
    color: #222022;
    margin: 10px;
}

.explore-dots-section .dot-item span {
    font-size: 12px;
    color: #666;
    margin: 10px;
    display: block;
}

/* View All Button */
.explore-dots-section .view-all {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.explore-dots-section .view-all button {
    background-color: #FED800;
    color: #222022;
    border: none;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s ease;
    border: 1px solid;
    width: 230px;
    height: 54px;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
}

.explore-dots-section .view-all button:hover {
    background-color: #FED800;
    color: #fff;
}

.explore-dots-section .slider-container .slick-slide {
    /* padding: 0 7px; */
    /* Adds a gap of 20px total (10px on each side) */
}

.explore-dots-section .dots-carousel {
    margin: 0 -7px;
    /* Cancels out the extra padding at the edges */
}

.explore-dots-section .dot-item {
    background: #f5f5f5;
    /* Optional: To make gaps visible */
    border-radius: 8px;
    /* Optional: Styling */
    overflow: hidden;
    /* Optional: Ensure content doesn't overflow */
}


.explore-dots-section .similar-dots-overlay {
    width: 100% !important;
}

.step-arrow-mobile {
    display: none;
}

.step-arrow {
    display: block;
}

.hide-category-mobile {
    display: block;
}

.explore-dots-section .similar-dot-title {
    display: block;
}

.explore-dots-section .carousel-loader-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
    /* Adjust height as per your carousel container */
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    /* Optional: Semi-transparent background */
}

.explore-dots-section .carousel-loader {
    width: 50px;
    /* Adjust the loader size */
    height: 50px;
    animation: spin 1.2s linear infinite;
    /* Add spin animation */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Style for carousel-container to ensure proper layering */
.explore-dots-section .carousel-container {
    position: relative;
    z-index: 1;
    width: 60%;
    margin: auto;
}


.custom-next-arrow {
    display: block;
    cursor: pointer;
    right: -35px;
    position: absolute;
    z-index: 2;
    top: 40%;
}

.custom-prev-arrow {
    display: block;
    cursor: pointer;
    left: -30px;
    position: absolute;
    z-index: 2;
    top: 40%
}

.text-container a {
    text-decoration: none;
}

.footer_bg_middle {
    position: absolute;
    bottom: 0;
    height: 100%;
    /* Full height of the navbar */
    width: 50%;
    /* Each div will cover 40% width */
    background-size: cover;
    /* Adjust size of the image to fit */
    background-repeat: no-repeat;
    background-position: center;
    /* Make sure the image is centered */
    pointer-events: none;
    /* This ensures the image doesn't block clicks */
    right: 0px;
    left: 0px;
    margin: auto;
    background-image: url('../images/landing_v2/blur_bottom.png');
}

.mobile-right,
.header-right-mobile {
    display: none;
}

.desktop-right,
.header-right-desktop {
    display: block;
}


.unique-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns */
    gap: 10px;
    max-width: 400px;
    /* margin: auto; */
}

.unique-grid-item {
    width: 100%;
    aspect-ratio: 1/1;
    /* Ensures square items */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
}

/* Background colors with unique classes */
.unique-grid-item.piggy-bank {
    background-color: #6DBD67;
}

/* Green */
.unique-grid-item.books-stack {
    background-color: #5876A3;
}

/* Blue */
.unique-grid-item.face-mask {
    background-color: #F7A9B5;
}

/* Pink */
.unique-grid-item.travel-bag {
    background-color: #4D97D0;
}

/* Light Blue */
.unique-grid-item.recipe-book {
    background-color: #F37C2F;
}

/* Orange */
.unique-grid-item.language-learning {
    background-color: #E5D0C4;
}

/* Beige */
.unique-grid-item.friendly-ghost {
    background-color: #F6A29D;
}

/* Peach */
.unique-grid-item.family-time {
    background-color: #D9B17D;
}

/* Yellow-Brown */
.unique-grid-item.alarm-clock {
    background-color: #9E83BF;
}

/* Purple */

.unique-grid-item img {
    /* max-width: 60%; */
    height: auto;
}

/* Special styling for GIFs to keep smooth animations */
.gif-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.overlay-content-hero-section p {
    text-align: left;
}

.download-page .footer-container {
    padding: 100px 120px 25px 120px;
}





@media only screen and (min-width: 2416px) {
    .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 42px 20px 42px 20px;
    }

    .download-page .page-wrapper{
        height: 95vh;
    }
}

@media only screen and (min-width: 1870px) {
    .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 64px 20px 64px 20px;
    }

    .download-page .page-wrapper{
        height: 95vh;
    }
}

@media only screen and (max-width: 768px) {
    .download-page .blurred-images-container-hero-section {
        display: block !important;
        padding: 0;
    }

    .desktop-right,
    .header-right-desktop {
        display: none;
    }
}

@media (max-width: 1864px) and (min-width: 1766px) {
    .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 42px 20px;
    }
    }

@media (max-width: 1256px) and (min-width: 1066px) {

    .beyond-chat-section.section-3 .beyond-title-2 {
        padding: 20px 0px 21px 0px;
    }

}

@media (max-width: 1433px) and (min-width: 1377px) {
/* 
    .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 20.2px;
    }

    .beyond-chat-section.section-3 .beyond-title-2 {
        padding: 15px 25px 25px 25px;
    } */

}

@media (max-width: 1335px) and (min-width: 1024px) {

    /* .beyond-chat-section.section-3 .text-container .beyond-title,
    .beyond-chat-section.section-3 .image-container .beyond-title {
        font-size: 22px;
        line-height: 30.3px;
    } */

    /* .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 41.7px;
    } */


}

@media (max-width: 1145px) and (min-width: 1100px) {

    /* .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 42px 25px 42px 25px;
    }

    .beyond-chat-section.section-3 .beyond-title-2 {
        padding: 15px 25px 25px 25px;
    } */

}

@media (max-width: 1101px) and (min-width: 1050px) {
/* 
    .beyond-chat-section.section-3 .beyond-title-1 {
        padding: 42px 25px 42px 25px;
    }

    .beyond-chat-section.section-3 .beyond-title-2 {
        padding: 15px 25px 25px 25px;
    } */

}

@media (max-width: 1199px) and (min-width: 997px) {
    /* Your styles here */

    .mobile-right,
    .header-right-mobile {
        display: block;
    }

    .desktop-right,
    .header-right-desktop {
        display: none;
    }

    .step {
        width: 30%;
    }
}


/* Responsive View for View All Button */
@media (max-width: 998px) {

    .unique-grid-item img {
        width: 80%;
    }

    .desktop-right,
    .header-right-desktop {
        display: none;
    }

    .mobile-right,
    .header-right-mobile {
        display: block;
    }

    .explore-dots-section .carousel-container {
        flex-wrap: wrap;
        width: 100%;
        /* Stack carousel and button on smaller screens */
    }

    .download-page .hero-section-container {
        min-height: auto;
    }

    .download-page .overlay-content-hero-section .cta-button{
        width: 330px;        
        font-size: 18px;       
    }


    .download-page .blurred-images-container-hero-section {
        margin-bottom: 1%;
        margin-top: 15%;
    }

    .explore-dots-section .view-all {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .explore-dots-section .dots-carousel .dot-item {
        width: 185px !important;
    }

    .how-it-works-section .section-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 33.6px;
        letter-spacing: 0.01em;
        text-align: left;
    }

    .how-it-works-section p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        text-align: left;
    }


    .step-circle {
        width: 32px;
        height: 32px;
    }

    .steps-container .margin-20 {
        margin-top: 0px;
    }

    .step h3 {
        font-size: 20px;
        font-weight: 600;
        line-height: 27.24px;
        text-align: left;
    }

    .step p {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        text-align: left;
    }

    .beyond-chat-section.section-4 .top-level-text {
        font-size: 28px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.01em;
        text-align: center;
        padding: 20px;
        border-top: 1px solid #333333;
    }

    .beyond-chat-section.section-4 .image-container{
        flex: 1 1;
    }

    .beyond-chat-section.section-4 .beyond-title-1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        letter-spacing: 0.01em;
        text-align: left;
        padding-bottom: 16px;
    }

    .beyond-chat-section.section-4 .beyond-sub-title-1 {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.07px;
        text-align: left;
    }

    .beyond-chat-section.section-4 {
        padding: 0px;
    }

    .mobile-right {
        display: block;
    }

    .beyond-chat-section.section-4 .content-container {
        display: flex;
    }

    .beyond-chat-section.section-4 .image-container {
        padding: 5px 0px 0px 10px;
    }

    .beyond-chat-section.section-4 .beyond-sub-title-1 {
        width: 80%;
        margin-top: 0px;
    }

    .beyond-chat-section.section-4 .top-level-text {
        border-left: 0px;
        border-right: 0px;
    }

    .step-arrow-mobile {
        display: block;
    }

    .step-arrow {
        display: none;
    }

    .steps-container {
        gap: 20px;
        flex-wrap: nowrap;
    }

    .step-circle {
        width: 35px;
        height: 35px;
    }

    .step-number {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.34px;
        letter-spacing: -0.1454544961452484px;
        text-align: center;
    }

    .beyond-chat-section .content-container {
        display: grid;
        gap: 24px;
    }

    .beyond-chat-section .text-container p{
        margin-bottom: 12px;
    }

    .beyond-chat-section .text-container ul {
        width: 100%;
    }

    .slide-up-overlay-slide-6.active {
        display: contents;
    }

    .askvasi-home .navbar {
        top: 0px;
    }

    .beyond-chat-section .text-container .beyond-title {
        font-size: 32px;
        font-weight: 600;
        line-height: 37.5px;
        letter-spacing: -1.1395477056503296px;
        text-align: left;
        /* color: #222022; */
    }

    .beyond-chat-section.section-3 .text-container .beyond-title,
    .beyond-chat-section.section-3 .image-container .beyond-title {

        font-size: 20px;
        font-weight: 600;
        line-height: 27.24px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    .beyond-chat-section .text-container p {
        font-size: 16px;
        line-height: 21.79px;
        text-align: left;
        width: 100%;
    }

    .overlay-content-hero-section p {
        font-size: 20px;
        font-weight: 400;
        line-height: 27.24px;
        text-align: center;
        width: 70%;
        margin: auto;
        padding: 20px 0px 20px 0px;
    }

    /* .download-page .overlay-content-hero-section .cta-button {
        padding: 21px 48px 21px 48px;
        width: 300px;
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
    } */

    .beyond-chat-section .text-container ul li {
        font-size: 14px;
        line-height: 19.07px;
    }

    .beyond-chat-section .text-container ul li strong {
        font-size: 14px;
        line-height: 19.07px;
    }

    .custom-list img {
        margin-right: 20px;
    }

    .beyond-chat-section .cta-button {
        margin: auto;
        display: block;
    }

    .explore-dots-section .section-title {
        font-size: 28px;
        font-weight: 600;
        line-height: 30.8px;
        text-align: center;
        width: 90%;
    }

    .explore-dots-section .section-subtitle {
        font-size: 16px;
        line-height: 21.79px;
    }

    .hide-category-mobile {
        display: none;
    }

    .explore-dots-section .category-buttons {
        width: 100%;
    }

    .explore-dots-section .category-buttons button {
        font-size: 14px;
        font-weight: 400;
        line-height: 15.25px;
    }

    .beyond-chat-section {
        /* padding: 50px 20px; */
        margin-top: 24px;
    }

    .explore-dots-section {
        margin-top: 10px;
        padding: 60px 20px;
    }

    .footer_bg_middle {
        width: 100%;
    }

    .try-btn-mobile {
        display: none;
    }

    .beyond-chat-section .footer_bg_left {
        left: 0px;
    }

    .explore-dots-section .dots-carousel .dot-item {
        width: 100% !important;
    }

    .cta-button:hover::before {
        transition: none;
        background: none;
    }

    .cta-button::before {
        transition: none;
        background: none;
    }

    .beyond-chat-section .cta-button:hover {
        color: #000;
    }

    .explore-dots-section .dot-item .dot-image {
        height: 200px;
    }

    .custom-next-arrow {
        right: 17px;
    }

    .custom-prev-arrow {
        left: -10px;
    }

    .how-it-works-section {
        padding: 20px 10px;
        padding-top: 0px !important;
    }

    .steps-container {
        display: grid;
        align-items: flex-start;
    }

    .step {
        width: 100%;
        height: 158px;
    }

    .step-arrow-container {
        margin-top: 10px;
    }

    /* .step {
        max-width: 125px;
    } */

    .explore-dots-section .slider-container .slick-slide {
        padding: 0 7px;
        /* Adds a gap of 20px total (10px on each side) */
    }

    .footer-description {
        font-size: 16px;
        line-height: 21.79px;
    }

    .beyond-chat-section.section-4 .content-container {
        border-left: 0;
        border-right: 0;
        /* border-bottom: 0; */
    }

    .download-page .container.how-to-create {
        padding: 0px;
    }

    .download-page .cta-button:hover{
        color: #000;
    }

    .download-page .explore-dots-section .view-all button:hover{
        color: #000;
    }

    .how-it-works-section p{
        margin-bottom: 16px;
    }

    .download-page .footer-container{
        padding: 10px;
    }

    .explore-dots-section .view-all button{
        width: 184px;
        font-size: 18px;
    }

}

@media only screen and (max-width: 768px) {

    .desktop-right {
        display: none;
    }

    .explore-dots-section .carousel-container {
        width: 100%;
    }

    .custom-next-arrow {
        right: -5px;
    }

    .download-page .container {
        /* padding: 0; */
    }

    .download-page .hero-title-1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        text-align: center;
        margin-right: 0%;
    }

    .download-page .hero-section-container {
        margin-top: 15%;
        border: 0;
        padding: 0;
        margin-bottom: 24px;
    }

    .download-page .overlay-content-hero-section .hero-title {
        margin-top: 75px;
    }
}

@media (max-width: 396px) {
    .desktop-right {
        display: none;
    }

    .explore-dots-section .section-title{
        width: 90%;
    }

    .explore-dots-section .dots-carousel .dot-item {
        width: 100% !important;
    }

    .custom-next-arrow {
        right: -5px;
    }
}

@media (max-width: 320px) {
    .explore-dots-section .section-title{
        width: 100%;
    } 
}


@media only screen and (max-width: 998px) and (orientation: landscape) {

    .download-page .hero-title-1 {
        /* font-size: 24px;
        font-weight: 700;
        line-height: 12.4px; */
    }

    .overlay-content-hero-section p {
        width: 100%;
        text-align: left;
    }

    /* .download-page .overlay-content-hero-section .cta-button {
        width: 230px;
    } */

    .how-it-works-section .section-title {
        font-family: Open Sans;
        font-size: 54px;
        font-weight: 600;
        line-height: 64.8px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-bottom: 16px;
    }

    .how-it-works-section p {
        font-size: 24px;
        font-weight: 400;
        line-height: 32.68px;
        text-align: left;
        margin-bottom: 36px;
    }

    .steps-container {
        display: flex;
    }


    .desktop-right {
        display: none;
    }

    .explore-dots-section .carousel-container {
        flex-wrap: wrap;
        /* Stack carousel and button on smaller screens */
    }

    .explore-dots-section .view-all {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .explore-dots-section .dots-carousel .dot-item {
        width: 185px !important;
    }

    .how-it-works-section .section-title {
        /* font-size: 20px;
        font-weight: 600;
        line-height: 27.24px;
        text-align: center; */
        margin-bottom: 24px;
        margin-top: 40px;
    }

    .step-circle {
        width: 32px;
        height: 32px;
    }

    .step h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 19.07px;
        letter-spacing: -0.1454544961452484px;
        /* text-align: center; */
    }

    .step p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.34px;
        /* text-align: center; */
    }

    .step {
        height: 160px;
    }

    .step-arrow-mobile {
        display: block;
    }

    .step-arrow {
        display: none;
    }

    .steps-container {
        gap: 10px;
        flex-wrap: nowrap;
    }

    .step-number {
        font-size: 12px;
        font-weight: 400;
        line-height: 16.34px;
        letter-spacing: -0.1454544961452484px;
        text-align: center;
    }

    .beyond-chat-section .content-container {
        display: grid;
    }

    .slide-up-overlay-slide-6.active {
        display: contents;
    }

    .askvasi-home .navbar {
        top: 0px;
    }

    .beyond-chat-section .text-container .beyond-title {
        font-size: 30px;
        font-weight: 600;
        line-height: 34.5px;
        letter-spacing: -1.1395477056503296px;
        text-align: left;
        /* color: #222022; */
    }

    .beyond-chat-section .text-container p {
        font-size: 16px;
        line-height: 21.79px;
        text-align: left;
    }

    .beyond-chat-section .text-container ul li {
        font-size: 14px;
        line-height: 19.07px;
    }

    .beyond-chat-section .text-container ul li strong {
        font-size: 14px;
        line-height: 19.07px;
    }

    .custom-list img {
        margin-right: 20px;
    }

    .beyond-chat-section .cta-button {
        margin: auto;
        display: block;
    }

    .explore-dots-section .section-title {
        font-size: 30px;
        line-height: 34.5px;
        letter-spacing: -1.1395477056503296px
    }

    .explore-dots-section .section-subtitle {
        font-size: 14px;
        line-height: 19.07px;
    }

    .hide-category-mobile {
        display: none;
    }

    .explore-dots-section .category-buttons {
        width: 100%;
    }

    .explore-dots-section .category-buttons button {
        font-size: 14px;
        font-weight: 400;
        line-height: 15.25px;
    }

    .beyond-chat-section {
        /* padding: 50px 20px; */
    }

    .explore-dots-section {
        /* padding: 70px 20px; */
    }

    .try-btn-mobile {
        display: none;
    }

    .beyond-chat-section .footer_bg_left {
        left: 0px;
    }

    .download-page .hero-section-container {
        margin-top: 10%;
    }

    .overlay-content-hero-section .hero-title {
        font-size: 35px;
        line-height: 40px;
    }

    .beyond-chat-section .beyond-chat-image {
        width: 100%;
    }

}