.phone-container {
    position: relative;
    width: 330px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 50px;
}

.phone-container .phone-frame {
    position: absolute;
    width: 85%;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    border-radius: 32px;
}

.phone-container .center {
    z-index: 3;
    transform: scale(1);
    opacity: 1;
}

.phone-container .left {
    z-index: 2;
    transform: translateX(-40%) scale(0.8);
    opacity: 0.3;
}

.phone-container .right {
    z-index: 2;
    transform: translateX(40%) scale(0.8);
    opacity: 0.3;
}

.phone-container .hidden {
    z-index: 1;
    transform: scale(0);
    opacity: 0;
}

.consumer-page .blurred-images-container-hero-section{
    padding-top: 55px;
}
